import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { FaDog } from "react-icons/fa";

import { firestore } from "../../../firebase/config";
import {
  AmberHeader,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpaceAroundArea,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import BookingStatus from "../../bookings/booking-status/booking-status.component";

const AcceptedJobDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobId } = useParams();

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState("");

  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [jobDetailsError, setJobDetailsError] = useState("");
  const [jobDetails, setJobDetails] = useState<BookingType | null>(null);

  //set onSnapshot (real time updates connection) for this job
  useEffect(() => {
    if (jobId && approvedDriver) {
      const unsubscribe = onSnapshot(
        doc(firestore, "bookings", jobId),
        { includeMetadataChanges: true },
        (jobDoc) => {
          if (jobDoc.exists()) {
            // Handle document existence
            if (!jobDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedBooking = {
                id: jobDoc.id,
                ...jobDoc.data(),
                createdAt: jobDoc.data().createdAt.toMillis(),
              };
              setJobDetails(updatedBooking as BookingType);
              setJobDetailsLoading(false);
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              !jobDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setJobDetailsLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              jobDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setJobDetailsLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "bookings", jobId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedBooking = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                    };
                    setJobDetails(updatedBooking as BookingType);
                    setJobDetailsLoading(false);
                  } else {
                    // Handle document not found
                    setJobDetailsError("Job not found");
                    setJobDetailsLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setJobDetailsError(error.message);
                    setJobDetailsLoading(false);
                  } else {
                    setJobDetailsError("Error fetching job");
                    setJobDetailsLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setJobDetailsError("Job not found");
            setJobDetailsLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setJobDetailsError(error.message);
            setJobDetailsLoading(false);
          } else {
            setJobDetailsError("Error fetching a job");
            setJobDetailsLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/drivers");
    }
  }, [approvedDriver, jobId, navigate]);

  const cancelActionHandler = () => {
    if (jobId) {
      setActionLoading(true);
      console.log("Cancel action...");
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const forwardActionHandler = () => {
    if (jobId) {
      setActionLoading(true);
      console.log("NEXT ACTION!");
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
    if (false) {
      setActionError("NO ERROR YET");
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || actionLoading || jobDetailsLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {actionError && !actionLoading && <RedHeader>{actionError}</RedHeader>}
      {jobDetailsError && !jobDetailsLoading && (
        <RedHeader>{jobDetailsError}</RedHeader>
      )}
      {!jobDetailsLoading && !actionLoading && !!jobDetails && (
        <>
          <GreenP>Your job on {jobDetails.date} at</GreenP>
          <GreenHeader>{jobDetails.time}</GreenHeader>
          <TextDivider>
            <GreenP> STATUS </GreenP>
          </TextDivider>
          <SpaceAroundArea>
            <ClickableArea onClick={cancelActionHandler}>
              <AmberHeader>Cancel Job</AmberHeader>
            </ClickableArea>
            <BookingStatus booking={jobDetails} />
            <ClickableArea onClick={forwardActionHandler}>
              <GreenHeader>Arrived? &rarr;</GreenHeader>
            </ClickableArea>
          </SpaceAroundArea>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={jobDetails} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{jobDetails.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{jobDetails.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(jobDetails.vehicleType)}</p>
          {jobDetails.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {jobDetails.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{jobDetails.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{jobDetails.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
        </>
      )}
    </Card>
  );
};

export default AcceptedJobDetails;
