import { Navigate, Route, Routes } from "react-router-dom";

import DriversIntro from "../../components/drivers/drivers-intro/drivers-intro.component";
import Application from "../../components/drivers/application/application.component";
import LoggedInRoute from "../../components/navigation/protected-routes/logged-in-route.component";
import UploadDocument from "../../components/drivers/upload-doc/upload-doc.component";
import VehicleList from "../../components/drivers/vehicle-list/vehicle-list.component";
import DriverPanel from "../../components/drivers/driver-panel/driver-panel.component";
import AcceptedJobs from "../../components/drivers/accepted-jobs/accepted-jobs.component";
import JobsHistory from "../../components/drivers/jobs-history/jobs-history.component";
import MyJobOffers from "../../components/drivers/my-job-offers/my-job-offers.component";
import PublicJobList from "../../components/drivers/public-job-list/public-job-list.component";
import DriverSettings from "../../components/drivers/driver-settings/driver-settings.component";
import ChangePreferencesFrom from "../../components/drivers/change-preferences-form/change-preferences-form";
import PublicJobDetails from "../../components/drivers/public-job-list/public-job-details";
import JobOfferDetails from "../../components/drivers/my-job-offers/job-offer-details";
import AcceptedJobDetails from "../../components/drivers/accepted-jobs/accepted-job-details";

const Drivers = () => {
  return (
    <Routes>
      <Route index element={<DriversIntro />} />
      <Route
        path="driver-panel"
        element={
          <LoggedInRoute>
            <DriverPanel />
          </LoggedInRoute>
        }
      />
      <Route
        path="application"
        element={
          <LoggedInRoute>
            <Application />
          </LoggedInRoute>
        }
      />
      <Route
        path="accepted-jobs"
        element={
          <LoggedInRoute>
            <AcceptedJobs />
          </LoggedInRoute>
        }
      />
      <Route
        path="accepted-job-details/:jobId"
        element={
          <LoggedInRoute>
            <AcceptedJobDetails />
          </LoggedInRoute>
        }
      />
      <Route
        path="jobs-history"
        element={
          <LoggedInRoute>
            <JobsHistory />
          </LoggedInRoute>
        }
      />
      <Route
        path="my-job-offers"
        element={
          <LoggedInRoute>
            <MyJobOffers />
          </LoggedInRoute>
        }
      />
      <Route
        path="job-offer-details"
        element={
          <LoggedInRoute>
            <JobOfferDetails />
          </LoggedInRoute>
        }
      />
      <Route
        path="public-job-list"
        element={
          <LoggedInRoute>
            <PublicJobList />
          </LoggedInRoute>
        }
      />
      <Route
        path="public-job-details"
        element={
          <LoggedInRoute>
            <PublicJobDetails />
          </LoggedInRoute>
        }
      />
      <Route
        path="settings"
        element={
          <LoggedInRoute>
            <DriverSettings />
          </LoggedInRoute>
        }
      />
      <Route
        path="change-preferences-form"
        element={
          <LoggedInRoute>
            <ChangePreferencesFrom />
          </LoggedInRoute>
        }
      />
      <Route
        path="vehicle-list"
        element={
          <LoggedInRoute>
            <VehicleList />
          </LoggedInRoute>
        }
      />
      <Route
        path="upload-doc/:docType/:numberPlate?"
        element={
          <LoggedInRoute>
            <UploadDocument />
          </LoggedInRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default Drivers;
