import { BookingType } from "./redux/bookings/bookings.types";
import { VehicleType } from "./redux/vehicles/vehicles.types";
import { colors } from "./style-variables";

//transform timestamp to date and time
export function transformTimestamp(timestamp: number) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(timestamp);

  const year = date.getFullYear().toString();
  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString();

  const hour = makeDoubleDigit(date.getHours()).toString();
  const minute = makeDoubleDigit(date.getMinutes()).toString();
  const second = makeDoubleDigit(date.getSeconds()).toString();

  // return formateTime(year, month, day, hour, minute, second);
  return `${day} ${month} ${year}, at ${hour}:${minute}:${second}`;
}
export function transformTimestampDateOnly(timestamp: number) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(timestamp);

  const year = date.getFullYear().toString();
  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString();

  // return formateTime(year, month, day, hour, minute, second);
  return `${day} ${month} ${year}`;
}
function makeDoubleDigit(x: number) {
  return x < 10 ? "0" + x : x;
}

export function secondsToHoursMinutesSeconds(d: number) {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

//get a timestamp of todays date without time for use in forms (like search purposes)
export function getTodaysDateTimestamp() {
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const currentDate = `${year}-${month}-${day}`;
  return new Date(currentDate).getTime();
}

//define status of a booking
export type BookingStatusType = {
  message: string;
  color: string;
};
export const defineBookingStatus = (booking: BookingType) => {
  const status: BookingStatusType = { message: "", color: "" };
  if (booking.notCovered) {
    status.message = "REQUEST EXPIRED";
    status.color = colors.warning;
  } else if (booking.cancelled) {
    status.message = "CANCELLED";
    status.color = colors.warning;
  } else if (booking.completed) {
    status.message = "COMPLETED";
    status.color = colors.light;
  } else if (booking.pickedUp) {
    status.message = "PASSENGER ON BOARD";
    status.color = colors.dark;
  } else if (booking.arrivedToPickup) {
    status.message = "DRIVER ARRIVED";
    status.color = colors.dark;
  } else if (booking.onRoute) {
    status.message = "DRIVER ON ROUTE";
    status.color = colors.dark;
  } else if (booking.accepted) {
    status.message = "ACCEPTED BY DRIVER";
    status.color = colors.dark;
  } else {
    status.message = "WAITING TO BE ACCEPTED";
    status.color = colors.primary;
  }
  return status;
};

//define vehicle type in user friendly format
export const defineVehicleType = (vehicleType: VehicleType) => {
  let selectedVehicleType: string;
  if (vehicleType === "4Seater") {
    selectedVehicleType = "Standard 4 seater vehicle";
  } else if (vehicleType === "4SeaterEstate") {
    selectedVehicleType = "Estate 4 seater vehicle";
  } else if (vehicleType === "6Seater") {
    selectedVehicleType = "6 seater vehicle";
  } else if (vehicleType === "minibus") {
    selectedVehicleType = "Minibus";
  } else if (vehicleType === "executive") {
    selectedVehicleType = "Executive vehicle";
  } else {
    selectedVehicleType = "";
  }
  return selectedVehicleType;
};

export const compareArraysOfStrings = (arr1: string[], arr2: string[]) => {
  // Check if the arrays have the same length. If not, they can't match.
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Create copies of the arrays and sort them. This allows us to compare them easily.
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  // Compare the sorted arrays element by element.
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false; // If any elements don't match, the arrays don't match.
    }
  }
  // If we get here, all elements matched, so the arrays are equal.
  return true;
};
